function initT(win, doc, script, path2script, elScript, firstScriptTag, ajtrkQ) {

    if(win.ajtrkExt) {
      console.log('exiting - ajtrk exists');
      return;
    }
    win.ajtrkQ = [];//
    win.ajtrk = function(){ console.log(arguments);win.ajtrkQ.push([].slice.apply(arguments))};

    elScript = doc.createElement(script),
    firstScriptTag = doc.getElementsByTagName(script)[0];
    elScript.async = 1;
    elScript.src = path2script;
    //what and where
    firstScriptTag.parentNode.insertBefore(elScript, firstScriptTag);
  }

function aJStartjTracker(trackingDatabase) {
    console.log('Start - AddTracker');
    initT(window, document, 'script', 'https://cdn.marketing-cloud.io/dmc-js/dmc-tracker.js', 'ajtrk');
    window.ajtrk('init', trackingDatabase, JSON.parse('{"fs":0,"scrl":0,"catchErrors":0,"mCl":0}') );    
    aJpageView();
    console.log('AJTRACKQ:', window.ajtrkQ);
    console.log('Tracker added');
}

function aJpageView() {
    console.log('aJpageView');
    window.ajtrk('pageView');
}
